.app-header {
  &.active {
    background: var(--deg-white);
  }
  .container {
    flex-direction: unset;
    .section-top {
      .logo-container {
        display: flex;
        align-items: center;
        .app-logo {
          .logo {
            width: 170px;
            height: auto;
          }
        }
      }
      .icon-hamburger-menu {
        @include screen-lg-max {
          display: flex;
        }
        &.mobile {
          line {
            stroke: var(--deg-pure-black);
          }
        }
      }
    }
    .section-bottom {
      .item {
        color: var(--deg-pure-black);
      }
    }
  }
}